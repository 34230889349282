// extracted by mini-css-extract-plugin
export var headerDescriptionContainer = "nft-module--headerDescriptionContainer--2f3E0";
export var headerDescriptionTitle = "nft-module--headerDescriptionTitle--2n4Kx";
export var headerDescriptionDesc = "nft-module--headerDescriptionDesc--1bJmR";
export var nftBanner = "nft-module--nftBanner--1zxMO";
export var image = "nft-module--image--yXOzB";
export var nftFAQContainer = "nft-module--nftFAQContainer--3lyU-";
export var faqTitle = "nft-module--faqTitle--1xffA";
export var accordion = "nft-module--accordion--29lpZ";
export var expandMoreIcon = "nft-module--expandMoreIcon--15Sm0";
export var heading = "nft-module--heading--309ce";
export var faqParagraph = "nft-module--faqParagraph--2iVK-";