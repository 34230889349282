import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import GlobalLayout from "../layouts/globalLayout";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage } from "gatsby-plugin-image";
import { MdExpandMore } from 'react-icons/md';
import * as styles from './nft.module.css';

const imagesQuery = graphql`{
    nftBanner: file(relativePath: {eq: "nft/CandleCollectionBannerNFT.png"}) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: CENTER})
      }
    },
  }`;

export default function Nft() {
    const images = useStaticQuery(imagesQuery);

  return (
    <GlobalLayout>
      <div className={styles.headerDescriptionContainer}>
        <div className={styles.headerDescriptionTitle}>NFTs</div>
        <div className={styles.headerDescriptionDesc}>Our NFTs represent a digital artwork of your new vintage item that is unique and owned by you!</div>
      </div>
      <div className={styles.nftBanner}>
        <GatsbyImage image={images.nftBanner.childImageSharp.gatsbyImageData} className={styles.image} />
      </div>
      <div className={styles.nftFAQContainer}>
        <h1 className={styles.faqTitle}>FAQs</h1>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={styles.heading}>What is an NFT?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                It is a "non-fungible token", which means that it is unique and cannot be replaced with something else. NFTs sit on the blockchain in order to manage the uniqueness and ownership of each token. In our case each digital art piece is unique to the vintage item that comes from our store. When we transfer the unique NFT to you that means you own it. 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography className={styles.heading}>Where can I find the NFT collection?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                You can find the collection on OpenSea under the Love Shack Vintage account: <a href="https://opensea.io/Love-Shack-Vintage">https://opensea.io/Love-Shack-Vintage</a>.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
            >
                <Typography className={styles.heading}>Are NFTs bad for the environment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                It depends on the technology that is used for each NFT. We purposefully used something called Polygon to make our NFTs. This technology requires less energy than the current Ethereum network to manage the NFTs on the blockchain.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel4a-content"
                id="panel4a-header"
            >
                <Typography className={styles.heading}>How can I claim my NFT after I ordered my magical vintage item?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Follow this blog post to claim the NFTs for the items you bought:&nbsp;
                <Link to={`/blog/claim-your-free-nft`} className={styles.meetCuteLink}>
                    How to Claim Your NFT
                </Link>.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel5a-content"
                id="panel5a-header"
            >
                <Typography className={styles.heading}>What do I do with an NFT?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                NFTs are just like a physical piece of art, but in the digital world. You can collect them, sell them to other art aficionados, or buy more. Feel free to add them to your social media profile or post about how beautiful your collection is. Once you own the NFTs, you can do anything you want with them.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
            <AccordionSummary
                expandIcon={<MdExpandMore className={styles.expandMoreIcon} />}
                aria-controls="panel6a-content"
                id="panel6a-header"
            >
                <Typography className={styles.heading}>How long do I have to claim the NFT?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                You have 90 days to claim the NFT after the date of your purchase. You may still claim your NFT after 90 days, but Love Shack Vintage reserves the right to sell the NFT in the case it is not claimed within 90 days.
            </Typography>
            </AccordionDetails>
        </Accordion>
      </div>
    </GlobalLayout>
    );
}
        